import styled from "@emotion/styled";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, Link, StaticQuery } from "gatsby";
import dayjs from "dayjs";
import React, { useState } from "react";
import tw from "twin.macro";
import * as Yup from "yup";
import { Responsive } from "../../utils/Responsive";
import { theme } from "../../utils/theme";
import { Header as LayoutHeader } from "../Header";
import { Logo } from "../Logo";
import { RichText } from "../RichText";
import { Space } from "../Space";
import { Body, H1, Input, Navlink, Select, Text } from "../Typography";
import { useAppointmentEvents } from "./hooks/useAppointmentEvents";
import { useBookAppointment } from "./hooks/useBookAppointment";

interface BookAppointmentFormValues {
    year: string;
    month: string;
    day: string;
    time: string | number;

    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    message: string;
}

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Your Name is required."),
    email: Yup.string()
        .email("Please enter a valid Email Address.")
        .required("Your Email is required."),
});

const initialValues = {
    year: "year",
    month: "month",
    day: "day",
    time: "time",

    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    message: "",
};

const BookAppointment = () => {
    const [isCompleted, setCompleted] = useState<boolean>(false);
    const { isBookAppointmentOpen, closeBookAppointment } =
        useBookAppointment();

    const handleSubmit = async (values: BookAppointmentFormValues) => {
        const day = getDayLabel(
            values.year,
            months.indexOf(values.month) + 1,
            Number(values.day) + 1
        );

        try {
            const res = await fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({
                    "form-name": "bookAppointment",
                    ...values,
                    day,
                }),
            });

            if (res.ok) {
                setCompleted(true);
            } else
                alert(
                    `${res.status}: ${res.statusText}. Please contact j@openweaves.com directly!`
                );
        } catch (error) {
            alert(error.message);
        }
    };

    const content = (
        <>
            <div
                tw="pointer-events-none absolute z-50 inset-0"
                style={{ maxHeight: "50vh" }}
            >
                <Header />
            </div>
            <motion.div
                tw="grid"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    ease: "easeInOut",
                    duration: 0.3,
                    delay: 0.8,
                }}
            >
                <div
                    tw="grid"
                    style={{
                        gridTemplateRows: "1fr 1fr",
                        background: theme.colors.paleGray,
                    }}
                >
                    <div
                        tw="grid py-3 2xl:py-6 px-5 2xl:px-8 justify-center items-center"
                        style={{
                            maxHeight: "50vh",
                        }}
                    >
                        <Responsive
                            sm={
                                <Body tw="pt-6">
                                    <BookAppointmentContent />
                                </Body>
                            }
                            md={
                                <div
                                    tw="grid justify-center items-center pt-6"
                                    style={{
                                        gridTemplateColumns: "1fr 2fr 1fr",
                                    }}
                                >
                                    <div />
                                    <Body>
                                        <BookAppointmentContent />
                                    </Body>
                                    <div />
                                </div>
                            }
                        />
                    </div>
                    <div
                        tw="grid"
                        style={{
                            gridTemplateRows: "1fr auto",
                            background: theme.colors.pistacio,
                        }}
                    >
                        <div
                            tw="grid"
                            style={{ gridTemplateRows: "1fr 1fr auto" }}
                        >
                            <div tw="grid justify-center items-center text-center px-8">
                                <Navlink as="span" tw="hover:no-underline">
                                    <Field name="year" component={YearSelect} />{" "}
                                    on{" "}
                                    <Field
                                        name="month"
                                        component={MonthSelect}
                                    />{" "}
                                    the{" "}
                                    <Field name="day" component={DaySelect} />{" "}
                                    at{" "}
                                    <Field name="time" component={TimeSelect} />
                                    .
                                </Navlink>
                            </div>
                            <div
                                tw="grid w-full"
                                style={{ gridTemplateColumns: "1fr 2fr 1fr" }}
                            >
                                <div />
                                <div>
                                    <div
                                        tw="grid gap-1 w-full"
                                        style={{
                                            gridTemplateColumns: "1fr 1fr",
                                        }}
                                    >
                                        <Field
                                            name="firstName"
                                            placeholder="First Name"
                                            component={Input}
                                        />
                                        <Field
                                            name="lastName"
                                            placeholder="Last Name"
                                            component={Input}
                                        />
                                    </div>
                                    <div
                                        tw="grid gap-1"
                                        style={{
                                            gridTemplateColumns: "1fr 1fr",
                                        }}
                                    >
                                        <Field
                                            name="mobile"
                                            placeholder="Mobile"
                                            component={Input}
                                        />
                                        <Field
                                            name="email"
                                            placeholder="Email"
                                            component={Input}
                                        />
                                    </div>
                                    <div tw="grid">
                                        <Field
                                            name="message"
                                            placeholder="Message"
                                            as="textarea"
                                            component={Input}
                                        />
                                    </div>
                                </div>
                                <div />
                            </div>
                            <div tw="grid justify-center py-3 2xl:py-6 px-5 2xl:px-8">
                                <Space.H>
                                    <ErrorMessage
                                        name="firstName"
                                        component={Text}
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component={Text}
                                    />
                                </Space.H>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </motion.div>
        </>
    );

    const submittedContent = (
        <>
            <div tw="grid" style={{ gridTemplateRows: "auto auto" }}>
                <div
                    tw="grid justify-center items-center"
                    style={{ background: theme.colors.paleGray }}
                >
                    <H1>Thank you for your booking,</H1>
                </div>
                <div
                    tw="grid justify-center items-center"
                    style={{ background: theme.colors.pistacio }}
                >
                    <H1>We look forwards to seeing you soon.</H1>
                </div>
            </div>
            <Nav>
                <div />
                <Navlink
                    as="button"
                    onClick={() => closeBookAppointment()}
                    type="button"
                >
                    Return
                </Navlink>
            </Nav>
        </>
    );

    return (
        <Formik
            name="bookAppointment"
            method="POST"
            data-netlify="true"
            {...{ initialValues, validationSchema }}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange={false}
        >
            {() => {
                return (
                    <Form tw="fixed z-50 overflow-hidden">
                        <div
                            tw="pointer-events-none absolute"
                            style={{ zIndex: -1, opacity: 0 }}
                        >
                            {content}
                        </div>
                        <Responsive
                            sm={
                                <AnimatePresence>
                                    {isBookAppointmentOpen && (
                                        <MobileOverlay
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                ease: "easeInOut",
                                                duration: 0.15,
                                            }}
                                        >
                                            <StyledContent
                                                initial={{ translateX: 16 }}
                                                animate={{ translateX: 0 }}
                                                exit={{ translateX: 16 }}
                                                transition={{
                                                    ease: "easeInOut",
                                                    duration: 0.15,
                                                }}
                                            >
                                                {!isCompleted
                                                    ? content
                                                    : submittedContent}
                                            </StyledContent>
                                        </MobileOverlay>
                                    )}
                                </AnimatePresence>
                            }
                            md={
                                <AnimatePresence>
                                    {isBookAppointmentOpen && (
                                        <Overlay>
                                            <StyledContent
                                                initial={{
                                                    translateX: "-100%",
                                                }}
                                                animate={{ translateX: "0%" }}
                                                exit={{ translateX: "-100%" }}
                                                transition={{
                                                    ease: "easeOut",
                                                    duration: 0.8,
                                                }}
                                            >
                                                {!isCompleted
                                                    ? content
                                                    : submittedContent}
                                            </StyledContent>
                                            <motion.div
                                                tw="bg-black grid fixed inset-0"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 0.8 }}
                                                exit={{ opacity: 0 }}
                                                transition={{
                                                    ease: "easeInOut",
                                                    duration: 0.8,
                                                }}
                                            />
                                        </Overlay>
                                    )}
                                </AnimatePresence>
                            }
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export { BookAppointment };

const Header = () => {
    const { closeBookAppointment } = useBookAppointment();
    return (
        <Responsive
            sm={
                <div tw="fixed inset-0 pointer-events-none">
                    <LayoutHeader />
                </div>
            }
            md={
                <StyledHeader>
                    <div tw="grid" style={{ gridTemplateColumns: "4fr 1fr" }}>
                        <Link to="/" onClick={() => closeBookAppointment()}>
                            <Logo />
                        </Link>
                    </div>
                    <div />
                </StyledHeader>
            }
        />
    );
};

const Footer = () => {
    const { closeBookAppointment } = useBookAppointment();
    return (
        <Nav>
            <Navlink
                as="button"
                type="button"
                onClick={() => closeBookAppointment()}
            >
                Close
            </Navlink>
            <div />
            <Navlink as="button" type="submit">
                Confirm
            </Navlink>
        </Nav>
    );
};

const BookAppointmentContent = () => {
    return (
        <StaticQuery
            query={graphql`
                query BookAppointmentQuery {
                    contentfulBookAppointment {
                        content {
                            raw
                        }
                    }
                }
            `}
            render={({ contentfulBookAppointment: { content } }) => (
                <RichText>{content}</RichText>
            )}
        />
    );
};

const StyledHeader = styled.div`
    ${tw`grid p-6`};
    grid-template-columns: 1fr 3fr;
`;

const Nav = styled.div`
    ${tw`py-3 2xl:py-6 px-5 2xl:px-8 absolute bottom-0 flex w-full z-40 items-end justify-between`};
    grid-template-columns: auto 1fr auto;
`;

const StyledContent = styled(motion.div)`
    ${tw`grid z-10`};
    background: ${theme.colors.paleGray};
`;

const Overlay = styled(motion.div)`
    ${tw`z-20 fixed grid inset-0`};
`;

const MobileOverlay = styled(motion.div)`
    ${tw`z-20 fixed grid w-full h-full`};
`;

const encode = (data) => {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
};

const YearSelect = ({ field, ...props }) => {
    return <Select {...field} {...props} options={years} />;
};

const MonthSelect = ({ field, ...props }) => {
    const currentMonthIndex = dayjs().month();

    return (
        <Select
            {...field}
            {...props}
            options={months}
            renderer={(month: string) => month.substring(0, 3)}
            disableOption={(month: string) =>
                months.indexOf(month) < currentMonthIndex
            }
        />
    );
};

const DaySelect = ({ field, ...props }) => {
    const { values } = useFormikContext<BookAppointmentFormValues>();
    const dayOptions = getDayOptions(values.year, values.month);
    const currentDayIndex = dayjs().date();
    const isCurrentMonth =
        months.indexOf(values.month) === dayjs().month() &&
        Number(values.year) === dayjs().year();

    return (
        <Select
            {...field}
            {...props}
            options={dayOptions}
            disabled={values.year === "year" || values.month === "month"}
            renderer={(day: string) =>
                getDayLabel(
                    values.year,
                    months.indexOf(values.month) + 1,
                    Number(day)
                )
            }
            disableOption={(day: number) => {
                const isAvailable = isCurrentMonth && day <= currentDayIndex;
                const isSunday = getDayLabel(
                    values.year,
                    months.indexOf(values.month) + 1,
                    day
                ).includes("Sun");
                return isSunday || isAvailable;
            }}
        />
    );
};

const TimeSelect = ({ field, ...props }) => {
    const { values } = useFormikContext<BookAppointmentFormValues>();
    const { appointments } = useAppointmentEvents();
    const currentDate = dayjs(
        `${values.year}-${months.indexOf(values.month) + 1}-${Number(
            values.day
        )}`
    );

    const isAvailable = (hour: number) =>
        appointments.some(({ start }) => {
            const currentHour = currentDate.startOf("day").add(hour, "hour");

            const appointmentString = dayjs(start).format();
            const hourString = currentHour.format();

            return appointmentString === hourString;
        });

    return (
        <Select
            {...field}
            {...props}
            options={hours}
            renderer={(hour: number) => {
                const currentHour = dayjs()
                    .startOf("day")
                    .add(hour, "hours")
                    .format("h:mm");
                return currentHour;
            }}
            disabled={
                values.year === "year" ||
                values.month === "month" ||
                values.day === "day"
            }
            disableOption={(hour: number) => isAvailable(hour)}
        />
    );
};

const hours = [8, 9.5, 11, 12.5, 14, 15.5, 17];

const years = [0, 1].map((i) => dayjs().year() + i);

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const getDayOptions = (year: string, monthLabel: string) => {
    if (!year) return [];
    if (!monthLabel) return [];

    const month = months.indexOf(monthLabel) + 1;
    const numDays = dayjs(`${year}-${month}`).daysInMonth();

    if (!numDays) return [];

    const dayOptions = [...new Array(numDays).fill(0).map((_, i) => i + 1)];

    return dayOptions;
};

const getDayLabel = (year: string, month: number, date: number) => {
    const labelNum = date < 10 ? `0${date}` : `${date}`;
    const labelString = `${dayjs(`${year}-${month}-${date}`).format("ddd")}`;

    const label = `${labelNum}-${labelString}`;

    return label;
};
